import React, { useState } from "react";
import emailjs from "emailjs-com";
import "./Contact.css";
import {
  AiOutlineGithub,
  AiOutlineInstagram,
  AiOutlineLinkedin,
} from "react-icons/ai";

//MAKE THIS SECURE

function Contact() {
  const [showNotification, setShowNotification] = useState({
    message: "",
    type: "",
  });

  const [formData, setFormData] = useState({
    name: "",
    email: "",
    message: "",
  });

  const handleChange = (e) => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value,
    });
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    // EmailJS sendForm method
    emailjs
      .send(
        "service_pg9uejd", //service template
        "template_zaqaj4c", //form template
        formData, //form data
        "c84Nxe3wBTlfc1QQm" //emailjs user id
      )
      .then(
        (response) => {
          console.log("SUCCESS!", response.status, response.text);
          setShowNotification({
            message: "Message sent successfully!",
            type: "success",
          });

          setFormData({ name: "", email: "", message: "" });
          // Hide notification after 3 seconds
          setTimeout(() => {
            setShowNotification({ message: "", type: "" });
          }, 3000);
        },
        (err) => {
          console.error("FAILED...", err);
          setShowNotification({
            message: "Failed to send the message. Please try again.",
            type: "error",
          });
          // Hide notification after 3 seconds
          setTimeout(() => {
            setShowNotification({ message: "", type: "" });
          }, 3000);
        }
      );
  };

  return (
    <div className="contact">
      <h1>Contact Me</h1>
      {/* Notification Pop-up */}
      {showNotification.message && (
        <div className={`notification ${showNotification.type}`}>
          {showNotification.message}
        </div>
      )}
      <form className="contact-form" onSubmit={handleSubmit}>
        <label htmlFor="name">Name</label>
        <input
          type="text"
          id="name"
          name="name"
          value={formData.name}
          onChange={handleChange}
          required
        />

        <label htmlFor="email">Email</label>
        <input
          type="email"
          id="email"
          name="email"
          value={formData.email}
          onChange={handleChange}
          required
        />

        <label htmlFor="message">Message</label>
        <textarea
          id="message"
          name="message"
          rows="5"
          value={formData.message}
          onChange={handleChange}
          required
        ></textarea>

        <button type="submit">Send Message</button>
      </form>
      {/* Social media icons */}
      <div className="social-media">
        <a
          href="https://www.linkedin.com/in/molly-sandler/"
          target="_blank"
          rel="noopener noreferrer"
        >
          <AiOutlineLinkedin size={40} />
        </a>
        <a
          href="https://instagram.com/mollyelaine_"
          target="_blank"
          rel="noopener noreferrer"
        >
          <AiOutlineInstagram size={40} />
        </a>
        <a
          href="https://github.com/mollysandler"
          target="_blank"
          rel="noopener noreferrer"
        >
          <AiOutlineGithub size={40} />
        </a>
      </div>
    </div>
  );
}

export default Contact;
