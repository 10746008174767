import React, { useState } from "react";
import "./About.css";

function About() {
  const images = [
    { src: "About/arches.jpg", caption: "Arches National Park, August 2024" },
    { src: "About/friends.jpg", caption: "SLO Motion formal!" },
    { src: "About/mdo.jpg", caption: "The beautiful central coast!" },
    {
      src: "About/colorado.jpg",
      caption: "Rocky Mountain National Park, August 2024",
    },
    {
      src: "About/dixpeak.jpg",
      caption: "Summiting Dix Peak in the Adirondacks!",
    },
    { src: "About/beans.jpg", caption: "Matching hats!" },
    { src: "About/trot.jpg", caption: "Happy Thanksgiving Turkey Trot!" },
    {
      src: "About/craterlake.jpg",
      caption: "Crater Lake National Park, August 2023",
    },
    { src: "About/frisbee.jpg", caption: "Team SLO Motion!" },
    {
      src: "About/tswift.jpg",
      caption: "Taylor Swift, 'The Eras Tour,' July 2023",
    },
  ];

  const [selectedImage, setSelectedImage] = useState(null);

  const handleClick = (image) => {
    setSelectedImage(image);
  };

  const handleClose = () => {
    setSelectedImage(null);
  };

  return (
    <div className="about">
      {/* Hero Section */}
      <section className="about-hero">
        <h1>About Me</h1>
      </section>

      {/* Work and Interests */}
      <div className="about-content">
        <div className="about-card work">
          <h2>Online</h2>
          <ul>
            <li>
              <strong>Exclusive Networks:</strong> I work in software
              development and product management at Exclusive Networks, focusing
              on creating new cybersecurity tools to make cyber safety more
              accessible to the average person.
              {/* I enjoy the challenges and learning opportunities
              that come with my profession. */}
            </li>
            <li>
              <strong> Reteti Elephant Sanctuary: </strong>I volunteer with a
              small team to enhance data storage systems for Reteti and the
              Sarara Foundation. I am working on connecting their operations to
              Salesforce for accurate tracking of donations, milk bottles, and
              elephant adoptions.
              {/* This
              invovles learning and integrating multiple technologies to work
              together seamlessly, including Squarespace, Make, Salesforce,
              Google Sheets, and Mailchimp. I love working for non profits, and
              really care about giving back to communitites whenever I can. */}
            </li>
            <li>
              <strong>Social Media:</strong> I am an avid user of social media,
              and love using my platforms to promote positivity and honesty
              online! I have worked with brands such as
              <a href="https://www.instagram.com/bragg/">
                {" "}
                Bragg Live Foods
              </a>{" "}
              to both run their social media and create content for them as an
              influencer.
            </li>
          </ul>
        </div>

        <div className="about-card offline">
          <h2>Offline</h2>
          <ul>
            <li>
              <strong>Ultimate Frisbee:</strong> I started playing ultimate
              frisbee since middle school, and have been playing ever since! I
              currently play for Cal Poly San Luis Obispo women's ultimate (SLO
              Motion), Santa Barbara mixed gender ultimate (Robot), and pickup
              frisbee
            </li>
            <li>
              <strong>Crocheting:</strong> I learned how to crochet in 2021, and
              then started to run an Etsy shop for my pieces, which you can
              check out{" "}
              <a
                href="https://www.etsy.com/shop/mollymakingthings"
                target="blank"
              >
                here!{" "}
              </a>
              Being a part of the small business community has been incredibly
              fullfilling.
            </li>
            <li>
              <strong>Hiking:</strong> I love spending time in nature,
              espescially hiking and backpacking, and am working towards getting
              my Adirondack 46 right now. I have 32/46 so far! Hiking the
              Tri-Tip Challenge in SLO with my roommates every year is a staple
              activity too.
              {/* I started this
              mission at Pok-O-Maccready summer camp, where I spent my
              childhood. Going on these hikes helps me reconnect with my old
              friends, and always brings us together every summer.  */}
            </li>
          </ul>
        </div>
      </div>

      {/* Image Gallery */}
      <h2 className="gallery-title"> Me In The Wild</h2>
      <div className="image-gallery">
        {images.map((image, index) => (
          <div
            className="gallery-item"
            key={index}
            onClick={() => handleClick(image)}
          >
            <img
              src={image.src}
              alt={image.caption}
              className="gallery-image"
            />
          </div>
        ))}
      </div>

      {/* Modal for Image and Caption */}
      {selectedImage && (
        <div className="modal" onClick={handleClose}>
          <div className="modal-content" onClick={(e) => e.stopPropagation()}>
            <span className="close" onClick={handleClose}>
              &times;
            </span>
            <img
              src={selectedImage.src}
              alt={selectedImage.caption}
              className="modal-image"
            />
            <p className="caption">{selectedImage.caption}</p>
          </div>
        </div>
      )}
    </div>
  );
}

export default About;
