// src/App.js
import React from "react";
import { BrowserRouter as Router } from "react-router-dom";
import { Element, scroller } from "react-scroll";
import Sidebar from "./Sidebar/Sidebar";
import Home from "./Home/Home";
import Projects from "./Projects/Projects";
import Contact from "./Contact/Contact";
import About from "./About/About";
import "./App.css";

function App() {
  const scrollToSection = (section) => {
    scroller.scrollTo(section, {
      duration: 800,
      delay: 0,
      smooth: "easeInOutQuart",
    });
  };

  return (
    <Router>
      <div className="app">
        <Sidebar scrollToSection={scrollToSection} />
        <div className="content">
          <Element name="home">
            <Home />
          </Element>
          <Element name="about">
            <About />
          </Element>
          <Element name="projects" className="projects">
            <Projects />
          </Element>
          <Element name="contact">
            <Contact />
          </Element>
        </div>
      </div>
    </Router>
  );
}

export default App;
