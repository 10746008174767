// src/Projects.js
import React from "react";
import "./Projects.css"; // Ensure you have this CSS file for styling

function Projects() {
  // Example project data
  const projects = [
    {
      title: "Reteti Elephant Sanctuary",
      description:
        "Worked with a team to fix Reteti's data storage system to properly connect to their frontend to their backend. This new system will accurately save all donations, milk bottle purchases, elephant adoptions, and other inputs into Salesforce. Also implementing these connections for their sister company, The Sarara Foundation.",
      imageUrl: "/reteti.png",
      link: "https://www.reteti.org/",
    },
    {
      title: "DogWorld",
      description:
        "A multiplayer game where a user controls a dog character using instruction blocks to complete levels. Also has a sandbox mode where users can create, save, and play their own levels. Integrates a weather API, uses AWS for the multiplayer connection, and has an arduino bluetooth dog that is controlled by the instruction blocks as well.",
      imageUrl: "/dogWorld.png",
      link: "https://github.com/mollysandler/DogWorld/",
    },
    {
      title: "Open-Recipes",
      description:
        "Collaborated with a team over a few weeks to develop a full stack recipe management app featuring ingredient uploads, recipe tagging, and other similar functionality, targeted at people living away from home for their first time. I worked on the backend development using Python and PostgreSQL, creating an API to interact with the frontend.",
      imageUrl: "/techspecs.png",
      link: "https://github.com/AlfredMadere/open-recipes",
    },
  ];

  return (
    <div className="projects">
      <h1>My Projects</h1>
      <div className="projects-list">
        {projects.map((project, index) => (
          <div key={index} className="project-item">
            <img
              src={project.imageUrl}
              alt={project.title}
              className="project-image"
            />
            <div className="project-details">
              <h2>{project.title}</h2>
              <p>{project.description}</p>
              <a href={project.link} target="_blank" rel="noopener noreferrer">
                Learn More
              </a>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
}

export default Projects;
