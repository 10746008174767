// src/Sidebar.js
import React from "react";
import { NavLink } from "react-router-dom";
import "./Sidebar.css";
import {
  AiOutlineHome,
  AiOutlineInfoCircle,
  AiOutlineProject,
  AiOutlinePhone,
} from "react-icons/ai";

function Sidebar({ scrollToSection }) {
  return (
    <div className="sidebar">
      <h2>Molly's World</h2>
      <nav>
        <ul>
          <li>
            <NavLink
              className={({ isActive }) => (isActive ? "active" : "")}
              onClick={() => scrollToSection("home")}
            >
              <AiOutlineHome />
              Home
            </NavLink>
          </li>
          <li>
            <NavLink
              className={({ isActive }) => (isActive ? "active" : "")}
              onClick={() => scrollToSection("about")}
            >
              <AiOutlineInfoCircle />
              About
            </NavLink>
          </li>
          <li>
            <NavLink
              className={({ isActive }) => (isActive ? "active" : "")}
              onClick={() => scrollToSection("projects")}
            >
              <AiOutlineProject />
              Projects
            </NavLink>
          </li>
          <li>
            <NavLink
              className={({ isActive }) => (isActive ? "active" : "")}
              onClick={() => scrollToSection("contact")}
            >
              <AiOutlinePhone />
              Contact
            </NavLink>
          </li>
        </ul>
      </nav>
    </div>
  );
}

export default Sidebar;
